import { useAuth } from "reactfire"
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { Button } from "@mui/material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const signIn = async (auth, navigate, postLoginRoute) => {
  const provider = new GoogleAuthProvider();

  await signInWithPopup(auth, provider);
  navigate(postLoginRoute)
}

export const LoginPage = () => {
  const auth = useAuth();
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate()

  return (
    <div style={{textAlign: 'center', margin: 'auto'}}>
      <Button variant="outlined" onClick={() => signIn(auth, navigate, searchParams.get('returnTo') || '/')}>Sign in with Google</Button>
    </div>
  )
}