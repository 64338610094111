import { useState } from "react"
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore'
import { useParams, Link } from "react-router-dom"
import { useFirestore, useFirestoreCollectionData, useFirestoreCollection, useFirestoreDocDataOnce, useFirestoreDocOnce, useSigninCheck } from "reactfire"
import { Button, CircularProgress, TextField, Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import { Remark } from "react-remark"
import { DataGrid } from '@mui/x-data-grid';

export const OrgAdminEventContainer = () => {
  const { orgId, eventId } = useParams();
  const { status: authStatus, data: signInCheckResult } = useSigninCheck();

  if (authStatus === 'loading') {
    return <CircularProgress />
  }
  return <OrgAdminEventDetailScreen orgId={orgId} eventId={eventId} uid={signInCheckResult.user.uid} />
}

const OrgAdminEventDetailScreen = ({ orgId, eventId, uid }) => {
  const eventRef = doc(useFirestore(), 'organizations', orgId, 'events', eventId)
  const { status, data } = useFirestoreDocDataOnce(eventRef)
  if (status === 'loading') {
    return <CircularProgress />
  }
  return (
    <>
      <Link to={`/admin/${orgId}`}><Button>Return to {orgId}</Button></Link>
      <p>ID: {data.id}</p>
      <p>Name: {data.details.name}</p>
      <p>Description:</p>
      <div style={{marginLeft: '30px'}}><Remark>{data.details.description}</Remark></div>
      <OrgAdminEventDetailAttendeeBlock orgId={orgId} eventId={eventId} uid={uid} event={data} />
    </>
  )
}

const flattenNestedFromDoc = (entry) => {
  var base = entry.data()
  Object.keys(base).forEach(grp => {
    if (typeof base[grp] === 'object') {
      Object.keys(base[grp]).forEach(k => {
        base[`${grp}__${k}`] = base[grp][k]
      })
    }
  })
  return base
}

const OrgAdminEventDetailAttendeeBlock = ({ orgId, eventId, uid, event }) => {
  const attendeesRef = collection(useFirestore(), 'organizations', orgId, 'events', eventId, 'attendees')
  const { status, data } = useFirestoreCollection(attendeesRef)
  if (status === 'loading') {
    return <CircularProgress />
  }

  console.log('Questions', event.questions)
  const columns = [
    {
      field: 'email',
      headerName: 'Email',
      width: 200
    },
    {
      field: 'ticketType',
      headerName: 'Ticket Type',
      width: 90
    },
    {
      field: 'timestamps__registered',
      headerName: 'Registered At',
      width: 100
    },
    ...Object.keys(event.questions || {}).map(q => ({field: `answers__${q}`, headerName: event.questions[q].prompt}))
  ]
  const rows = data.docs.map(flattenNestedFromDoc)
  console.log('Rows are', rows)
  return (
    <Accordion>
      <AccordionSummary>
        Attendee Count: {data.size}
      </AccordionSummary>
      <AccordionDetails>
        <DataGrid rows={rows} columns={columns} />
      </AccordionDetails>
    </Accordion>
    
  )
}