
export class Cart {
  constructor(reference, grandTotal, subtotal, serviceCharge, invalid, dirty, attendees) {
    this.reference = reference
    this.grandTotal = grandTotal
    this.subtotal = subtotal
    this.serviceCharge = serviceCharge
    this.invalid = invalid
    this.dirty = dirty
    this.attendees = attendees
  }
}

class Attendee {
  constructor(email, ticketType, answers) {
    this.email = email
    this.ticketType = ticketType
    this.answers = answers
  }
}

export class CartConverter {
  toFirestore(cart) {
    return {
      attendees: (cart.attendees || []).map((new AttendeeConverter()).toFirestore),
      invalid: true,
      dirty: true,
      waiting: false // Unset flag that is set when saving starts
    }
  }
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options)
    console.log('Raw data is', data);
    return new Cart(
      snapshot.reference,
      data.grandTotal,
      data.subtotal,
      data.service_charge,
      data.invalid,
      data.dirty,
      (data.attendees || []).map((new AttendeeConverter()).fromNestedFirestore)
    )
  }
}

class AttendeeConverter {
  toFirestore(attendee) {
    return {
      email: attendee.email || '',
      ticketType: attendee.ticketType || '',
      answers: attendee.answers || []
    }
  }
  fromNestedFirestore(data) {
    return new Attendee(
      data.email,
      data.ticketType,
      data.answers
    )
  }
}