import { useFirestore, useFirestoreDocDataOnce } from "reactfire"
import { Button } from "@mui/material";
import { doc } from 'firebase/firestore'
import { useParams, Link } from "react-router-dom"
import { Remark } from 'react-remark';

export const EventHome = () => {
  const { orgId, eventId } = useParams();
  const eventRef = doc(useFirestore(), 'organizations', orgId, 'events', eventId);
  const { status, data } = useFirestoreDocDataOnce(eventRef);
  if (status == 'loading') {
    return <p>Loading...</p>
  }
  if (!data) {
    // TODO: Make this redirect to home
    return <p>Event {orgId}-{eventId} not found.</p>
  }
  // TODO: Fetch public events as well
  return (
    <div>
      <h2>{data.details.name}</h2>
      <Remark>
        {data.details.description}
      </Remark>
      <p>
        <Link to="cart"><Button variant="contained">Buy Tickets</Button></Link>
      </p>
    </div>
  )
}