import { useCallback, useEffect, useState } from 'react'
import { doc, updateDoc } from 'firebase/firestore'
import { useFirestore, useFirestoreDocDataOnce, useFirestoreDoc, useFirestoreDocOnce, useSigninCheck } from "reactfire"
import { useParams, Link, useNavigate } from "react-router-dom"
import { CartConverter, Cart } from "../models/Cart";
import { Button, CircularProgress, Typography, Container, TextField, FormGroup, FormControl, FormLabel, FormControlLabel, Switch, RadioGroup, Radio, Stack, Box, Popover, debounce } from "@mui/material";
import { formatPrice } from "../utils/formatPrice";
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import { SQUARE_APP_ID } from '../config'


const CartCheckoutScreenInternal = ({ orgId, eventId, uid, token }) => {
  const navigate = useNavigate()
  const cartRef = doc(useFirestore(), 'organizations', orgId, 'events', eventId, 'carts', uid).withConverter(new CartConverter());
  const { status, data } = useFirestoreDoc(cartRef);
  const [locationId, setLocationId] = useState()
  const [buttonLoading, setButtonLoading] = useState(false)

  const fetchRequest = useCallback(debounce(() => {
    console.log('Invoking request')
    fetch(
      `/api/events/${orgId}/${eventId}/payments/square/start`,
      {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    ).then(response => response.json()).then(data => {
      console.log('Data is ', data)
      if (data.error) {
        console.log('Redirecting')
        navigate(`/${orgId}/${eventId}/cart`)
      }
      setLocationId(data.locationId);
    }).catch(err => {
      console.log('Error is', err)
    });
  }, 100), [token, orgId, eventId])

  useEffect(() => {
    if (uid && token) {
      fetchRequest()
    }
  }, [fetchRequest, uid, token])

  const submitNonce = useCallback((nonce) => {
    fetch(
      `/api/events/${orgId}/${eventId}/payments/square/complete`,
      {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ nonce })
      }
    ).then(data => {
      console.log('Data is ', data)
      setButtonLoading(false)
    }).catch(err => {
      console.log('Error is', err)
    });
  }, [orgId, eventId])
  useEffect(() => {
    if (status !== 'loading') {
      if (!data.data()) {
        navigate(`/${orgId}/${eventId}`)
      }
    }
  }, [data, status])

  if ((status == 'loading') || !locationId) {
    return <CircularProgress />
  }
  const cart = data.data()
  console.log('Cart is', cart);
  if (!cart) {
    return <></>
  }

  return (
    <Stack>
      <Box display="flex" alignItems="center" width="auto" mx="auto" height="auto" my="20px">
        Please enter your payment information:
      </Box>
      <Box display="flex" alignItems="center" width="auto" mx="auto" height="auto">
        <PaymentForm
          applicationId={SQUARE_APP_ID}
          cardTokenizeResponseReceived={(tokenData, buyer) => {
            setButtonLoading(true)
            const { token } = tokenData
            submitNonce(token);
          }}
          locationId={locationId}
        >
          <CreditCard
            buttonProps={{
              isLoading: buttonLoading
            }}
          >
            Pay {formatPrice(cart.grandTotal)}
          </CreditCard>
        </PaymentForm>
      </Box>
    </Stack>
  )
}

export const CartCheckoutScreen = () => {
  const { orgId, eventId } = useParams();
  const { status: authStatus, data: signInCheckResult } = useSigninCheck();
  const [token, setToken] = useState(null);
  const [uid, setUid] = useState(null);
  useEffect(() => {
    if (authStatus !== 'loading') {
      signInCheckResult.user.getIdToken().then(setToken)
      setUid(signInCheckResult.user.uid)
    }
  }, [authStatus, signInCheckResult, setToken, setUid])

  if ((authStatus == 'loading') || (token == null) || (uid == null)) {
    return <CircularProgress />
  }

  return <CartCheckoutScreenInternal orgId={orgId} eventId={eventId} uid={uid} token={token} />

}