export const firebaseConfig = {
  apiKey: "AIzaSyDjUMazfFYA4afa4H5BR13hzxjeieqYFf4",
  authDomain: "myconreg-c65d2070-prod.firebaseapp.com",
  projectId: "myconreg-c65d2070-prod",
  storageBucket: "myconreg-c65d2070-prod.appspot.com",
  messagingSenderId: "519209560877",
  appId: "1:519209560877:web:e15870d9c9390c6e1d41a4",
  measurementId: "G-V560X13RS8"
};
export const SQUARE_IS_SANDBOX = false;
export const SQUARE_CLIENT_ID = 'sq0idp-fZX1xYDnCtimjx5K06ZyhQ';
export const SQUARE_APP_ID = 'sq0idp-fZX1xYDnCtimjx5K06ZyhQ';