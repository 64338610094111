import { Navigate, useLocation } from 'react-router-dom';
import { useSigninCheck } from 'reactfire';

export const RequireAuth = ({ children }) => {
  console.log('RA top Fire')
  const { status, data: signInCheckResult } = useSigninCheck();
  let location = useLocation();

  if (status == 'loading') {
    return <>Loading...</>
  }
  console.log('RA Fire')

  if (signInCheckResult.signedIn === true) {
    return children
  }

  return <Navigate to={`/login?returnTo=${location.pathname}`} replace />
}