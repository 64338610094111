import { useFirestore, useFirestoreDocDataOnce, useFirestoreDocOnce, useSigninCheck } from "reactfire"
import { doc } from 'firebase/firestore'
import { Link, useParams } from "react-router-dom"
import { CircularProgress } from "@mui/material"
import { useState, useEffect } from "react"

const OrgAdminButton = ({ orgId, uid }) => {
  console.log('UID', uid)
  const adminRef = doc(useFirestore(), 'organizations', orgId, 'admins', uid)
  const { status, data } = useFirestoreDocDataOnce(adminRef)
  if (status == 'loading') {
    return <></>
  }
  if (data) {
    return <p><Link to="admin">Admin Page</Link></p>
  }
}

export const OrgHome = () => {
  const { orgId } = useParams();
  const orgRef = doc(useFirestore(), 'organizations', orgId);
  const { status, data } = useFirestoreDocDataOnce(orgRef);
  const { status: authStatus, data: signInCheckResult } = useSigninCheck();
  const [uid, setUid] = useState(null)
  useEffect(() => {
    if (signInCheckResult && signInCheckResult.signedIn && signInCheckResult.user) {
      setUid(signInCheckResult.user.uid)
    }
  }, [signInCheckResult, setUid])

  if ((status == 'loading') || (authStatus == 'loading')) {
    return <CircularProgress />
  }
  if (!data) {
    // TODO: Make this redirect to home
    return <p>Organization {orgId} not found.</p>
  }
  // TODO: Fetch public events as well
  return (
    <div>
      {data.details.name}
      {uid && <OrgAdminButton orgId={orgId} uid={uid} />}
    </div>
  )
}