import { useState } from 'react'
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore'
import { Link } from 'react-router-dom'
import { useFirestore, useFirestoreCollectionData, useFirestoreDocDataOnce, useFirestoreDocOnce, useSigninCheck } from "reactfire"
import { AdminOrgContainer } from './admin/OrganizationScreens'
import { Button, CircularProgress, TextField } from "@mui/material"
import { useForm } from "react-hook-form"

export const AdminScreen = () => {
  const { status: authStatus, data: signInCheckResult } = useSigninCheck();
  const [ isCreating, setIsCreating ] = useState(false)

  if (authStatus === 'loading') {
    return <CircularProgress />
  }
  if (isCreating) {
    return <CreateOrgScreen cancelCreate={() => setIsCreating(false)} uid={signInCheckResult.user.uid} />
  }
  return (
    <>
      <div>
        <Button onClick={() => setIsCreating(true)}>Create New Organization</Button>
      </div>
      <OrganizationListScreen uid={signInCheckResult.user.uid} />
    </>
  )
}

const CreateOrgScreen = ({ cancelCreate, uid }) => {
  const { register, handleSubmit } = useForm()
  const onSubmit = async (data) => {
    await setDoc(doc(getFirestore(), 'organizations', data.org_short, 'admins', uid), {
      permission_level: 3
    })
    await setDoc(doc(getFirestore(), "organizations", data.org_short), {
      details: {
        name: data.org_name
      }
    })
  }
  return (
    <>
      <Button onClick={() => cancelCreate()}>Cancel</Button>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField label="Org Short Name" {...register('org_short', { required: 'Please enter a short name for your organization. This cannot be changed later.'})} />
        <TextField label="Org Full Name" {...register('org_name', { required: 'Please enter the full name for your organization.'})} />
        <Button type="submit">Submit</Button>
      </form>
    </>
  )
}

const OrganizationListScreen = ({ uid }) => {
  const userRef = doc(useFirestore(), 'users', uid)
  const { status, data } = useFirestoreDocDataOnce(userRef)

  if (status == 'loading') {
    return <CircularProgress />
  }
  return (
    <>
      { Object.keys((data || {}).organizations || {}).map(orgId => <OrgDetailLink orgId={orgId} />)}
    </>
  )
}

const OrgDetailLink = ({ orgId }) => {
  const orgRef = doc(useFirestore(), 'organizations', orgId)
  const { status, data } = useFirestoreDocDataOnce(orgRef)
  if (status == 'loading') {
    return <CircularProgress />
  }
  return <Link to={`/admin/${orgId}`}><Button>{data.details.name}</Button></Link>
}

