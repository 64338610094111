import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Outlet,
} from "react-router-dom";
import { MarketingHome } from './screens/MarketingHome';
import { Layout } from './Layout';
import { OrgHome } from './screens/OrgHome'; 
import { EventHome } from './screens/EventHome';
import { CartScreen } from './screens/Cart';

import { FirebaseAppProvider, FirestoreProvider, AuthProvider, useFirebaseApp, useAuth, useSigninCheck } from 'reactfire';
import { firebaseConfig } from './config';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { LoginPage } from './screens/LoginPage';
import { CartCheckoutScreen } from './screens/CartCheckout'
import { AdminScreen } from './screens/Admin'
import { AdminProcessOauth } from './screens/AdminProcessOauth'

import { RequireAuth } from './utils/RequireAuth';
import { AdminOrgContainer } from './screens/admin/OrganizationScreens';
import { OrgAdminEventContainer } from './screens/admin/EventScreens';

const AppAfterBootstrap = () => {
  console.log('App after')
  const router = createBrowserRouter([
    {
      id: 'root',
      path: '/',
      element: <Layout />,
      children: [
        {
          index: true,
          element: <MarketingHome />
        },
        {
          path: 'login',
          element: <LoginPage />
        },
        {
          path: 'admin',
          element: (
            <RequireAuth>
              <Outlet />
            </RequireAuth>
          ),
          children: [
            {
              index: true,
              element: (
                <AdminScreen />
              )
            },
            {
              path: 'oauth/square/token',
              element: (
                <AdminProcessOauth />
              )
            },
            {
              path: ':orgId',
              children: [
                {
                  index: true,
                  element: (<AdminOrgContainer />)
                },
                {
                  path: ':eventId',
                  element: (<OrgAdminEventContainer />)
                }
              ]
            }
          ]
        },
        {
          path: ':orgId',
          children: [
            {
              index: true,
              Component: OrgHome
            },
            {
              path: ':eventId',
              children: [
                {
                  index: true,
                  Component: EventHome
                },
                {
                  path: 'cart',
                  children: [
                    {
                      index: true,
                      element: (
                        <RequireAuth>
                          <CartScreen />
                        </RequireAuth>
                      )
                    },
                    {
                      path: 'checkout',
                      element: (
                        <RequireAuth>
                          <CartCheckoutScreen />
                        </RequireAuth>
                      )
                    }
                  ]
                }
              ]
            }
          ] 
        }
      ]
    }
  ]);
  const firestoreInstance = getFirestore(useFirebaseApp());
  const authInstance = getAuth(useFirebaseApp());

  return (
    <AuthProvider sdk={authInstance}>
      <FirestoreProvider sdk={firestoreInstance}>
        <RouterProvider router={router} />
      </FirestoreProvider>
    </AuthProvider>
  );
}

const App = () => {
  console.log('App firing')
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <AppAfterBootstrap />
    </FirebaseAppProvider>
  )
}

export default App;
