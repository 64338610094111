import { CircularProgress, debounce } from "@mui/material"
import { useSigninCheck } from 'reactfire'
import { useCallback, useEffect, useState } from "react"
import { useLocation } from 'react-router-dom'
import { useFirestore } from "reactfire"

const useQuery = () => new URLSearchParams(useLocation().search)

const fetchFromStorageSafely = () => {
  try {
    const validationInfo = JSON.parse(localStorage.getItem('__squareLink'))
    return validationInfo
  } catch (err) {
    window.location.href = '/' // Invalid
  }
}

export const AdminProcessOauthInternal = ({ orgId }) => {
  const query = useQuery()
  const { status: authStatus, data: signInCheckResult } = useSigninCheck();

  const claimRequest = useCallback(debounce((token) => {
    return fetch(
      `/api/admin/organizations/${orgId}/square/claim`,
      {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          code: query.get('code')
        })
      }
    ).then(response => response.json()).then(data => {
      console.log('Data is ', data)
      window.location.href = `/${orgId}/admin`
    }).catch(err => {
      console.log('Error is', err)
    });
  }), [orgId, query])

  useEffect(() => {
    if (authStatus !== 'loading') {
      if (signInCheckResult.user) {
        signInCheckResult.user.getIdToken().then(token => {
          claimRequest(token)
        })
      } else {
        window.location.href = `/${orgId}/admin`
      }
    }

  }, [authStatus, signInCheckResult])
  return <></>
}

export const AdminProcessOauth = () => {
  const query = useQuery()
  const [fetchedOrgId, setOrgId] = useState()
  useEffect(() => {
    const { validationToken, orgId } = fetchFromStorageSafely()
    console.log('validations', query.get('state'), validationToken)
    if (query.get('state') !== validationToken) {
      console.log('Validation Token did not match')
      window.location.href = '/'
    } else {
      console.log('in else', orgId)
      setOrgId(orgId)
    }
  }, [setOrgId])
  if (!fetchedOrgId) {
    return <CircularProgress />
  }
  return <AdminProcessOauthInternal orgId={fetchedOrgId} />
}