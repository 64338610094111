import { useFirestore, useFirestoreDocDataOnce, useFirestoreDoc, useFirestoreDocOnce, useSigninCheck } from "reactfire"
import { doc, setDoc, updateDoc } from 'firebase/firestore'
import { useParams, Link } from "react-router-dom"
import { Button, CircularProgress, Typography, Container, TextField, FormGroup, FormControl, FormLabel, FormControlLabel, Switch, RadioGroup, Radio, Stack, Box } from "@mui/material";
import { CartConverter, Cart } from "../models/Cart";
import { useFieldArray, useForm, useWatch, Controller } from 'react-hook-form'
import debounce from 'lodash.debounce'
import { useCallback, useState, useEffect } from "react";
import { formatPrice } from "../utils/formatPrice";


const CartGrandTotal = ({ cartRef }) => {
  const dupRef = doc(useFirestore(), cartRef.path)
  const { status, data: rawCart } = useFirestoreDoc(dupRef);
  if (status == 'loading') {
    return <CircularProgress />
  }
  // console.log('cr', cartRef, 'status', status, 'rawCart', rawCart)
  
  const cart = rawCart.data()
  // console.log('cgt', cart)
  if (!cart || cart.dirty) {
    return (
      <Box height="50px">
        <CircularProgress />
      </Box>
    )
  }
  return (
    <Box height="50px">
      <Typography fontSize='24px'>
        {formatPrice(cart.grandTotal)}
      </Typography>
    </Box>
  )

}

const formatTicketType = ttObject => `${ttObject.name} (${formatPrice(ttObject.price)})`;

const CartForm = ({ event, control, register }) => {
  const { fields, append, remove } = useFieldArray({ 
    control,
    name: 'attendees'
  })
  return (
    <Box width='400px' display="flex" flexDirection="column" alignItems="center">
      <Box width='400px'>
        <form>
          {fields.map((item, index) => (
            <Container key={item.id} sx={{minWidth: '200px'}}>
              <Stack spacing={2}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Select Ticket</FormLabel>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name={`attendees.${index}.ticketType`}
                    render={({field}) => (
                      <RadioGroup {...field}>
                        {Object.keys(event.ticketTypes).toSorted().map(ttIdent => event.ticketTypes[ttIdent].public && (<FormControlLabel key={ttIdent} control={<Radio />} value={ttIdent} label={formatTicketType(event.ticketTypes[ttIdent])}/>))}
                      </RadioGroup>
                    )} 
                  />
                </FormControl>

                <TextField {...register(`attendees.${index}.email`)} label="Email" />
                { Object.keys(event.questions).map((key) => {
                  switch (event.questions[key].qtype) {
                    case 'yesno':
                      return (
                        <FormGroup key={key}>
                          <FormControlLabel control={<Switch {...register(`attendees.${index}.answers.${key}`)} />} label={event.questions[key].prompt} />
                        </FormGroup>
                      )
                    case 'mchoice':
                      return (
                        <FormControl key={key}>
                          <FormLabel>{event.questions[key].prompt}</FormLabel>
                          <Controller
                            rules={{ required: !!event.questions[key].required }}
                            control={control}
                            name={`attendees.${index}.answers.${key}`}
                            render={({field}) => (
                              <RadioGroup {...field}>
                                {event.questions[key].choices.map(choice => (<FormControlLabel control={<Radio />} value={choice} label={choice} />))}
                              </RadioGroup>
                            )} 
                          />
                        </FormControl>
                      )
                    case 'long':
                      return <TextField key={key} {...register(`attendees.${index}.answers.${key}`)} label={event.questions[key].prompt} multiline rows={5} />;
                    case 'short':
                    default:
                      return <TextField key={key} {...register(`attendees.${index}.answers.${key}`)} label={event.questions[key].prompt} />;
                  }
                })}
                <Button onClick={() => remove(index)}>Remove Attendee</Button>
              </Stack>
              <hr />
            </Container>
          ))}
        </form>
      </Box>
      <Box>
        <Button variant="contained" onClick={() => append({})}>Add Attendee</Button>
      </Box>
    </Box>
  )
}
const CartSaver = ({ cartRef, control }) => {
  const saveFunction = useCallback(debounce((values) => {
    console.log('Trying to set values after debounce:', values)
    updateDoc(cartRef, new CartConverter().toFirestore(values));
  }, 1000), [cartRef])
  
  const results = useWatch({ control })
  
  useEffect(() => {
    updateDoc(cartRef, {dirty: true, invalid: true, waiting: true}).then(() => saveFunction(results))
  }, [results])

  return <></>
}
const CartCheckoutLink = ({ cartRef }) => {
  const { status, data: rawCart } = useFirestoreDoc(cartRef);
  if (status == 'loading') {
    return <CircularProgress />
  }
  
  const cart = rawCart.data()
  // console.log('Cart Checkout', cart)
  if (!cart || cart.invalid || cart.dirty) {
    return (
      <Box mt="10px">
        <Button variant="contained" disabled>
          Checkout
        </Button>
      </Box>
    )
  }
  return (
    <Box mt="10px">
      <Link to="checkout">
        <Button variant="contained">
          Checkout
        </Button>
      </Link>
    </Box>
  )
}

const CartDisplay = ({ cartRef, initialCart, event }) => {
  const { control, register } = useForm({ defaultValues: initialCart })
  return (
    <Container width="auto" mx="auto">
      <Stack sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <CartGrandTotal cartRef={cartRef} />
        <CartForm event={event} control={control} register={register} />
        <CartSaver cartRef={cartRef} control={control} />
        <CartCheckoutLink cartRef={cartRef} />
      </Stack>
    </Container>
  )
}

const GuaranteeCartExists = ({ orgId, eventId, uid }) => {
  const cartRef = doc(useFirestore(), 'organizations', orgId, 'events', eventId, 'carts', uid).withConverter(new CartConverter());
  const { status, data } = useFirestoreDocOnce(cartRef);
  const [checkPassed, setCheckPassed] = useState(false)
  useEffect(() => {
    if (status !== 'loading') {
      const cart = data.data()
      if (cart) {
        setCheckPassed(true)
      } else {
        setDoc(cartRef, new CartConverter().toFirestore({})).then(() => setCheckPassed(true));
      }
    }
  }, [setCheckPassed, data, cartRef, status])

  if (!checkPassed) {
    return <CircularProgress />
  }
  return <CartInternal orgId={orgId} eventId={eventId} uid={uid} />
}

const CartInternal = ({ orgId, eventId, uid }) => {
  const cartRef = doc(useFirestore(), 'organizations', orgId, 'events', eventId, 'carts', uid).withConverter(new CartConverter());
  const newCartRef = doc(useFirestore(), 'organizations', orgId, 'events', eventId, 'carts', uid).withConverter(new CartConverter());
  const eventRef = doc(useFirestore(), 'organizations', orgId, 'events', eventId);
  const { status, data } = useFirestoreDocOnce(cartRef);
  const { status: eventStatus, data: eventData } = useFirestoreDocDataOnce(eventRef);

  if ((status == 'loading') || (eventStatus == 'loading')) {
    return <CircularProgress />
  }
  const cart = data.data()

  return (
    <CartDisplay cartRef={newCartRef} initialCart={cart} event={eventData} />
  )
}

export const CartScreen = () => {
  const { orgId, eventId } = useParams();
  const { status: authStatus, data: signInCheckResult } = useSigninCheck();

  if (authStatus == 'loading') {
    return <CircularProgress />
  }

  return <GuaranteeCartExists orgId={orgId} eventId={eventId} uid={signInCheckResult.user.uid} />
}