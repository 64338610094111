import { Link, Outlet, useLocation } from "react-router-dom"
import { Container, Stack, AppBar, Toolbar, CircularProgress, Button, Typography, Box } from '@mui/material';
import { useAuth, useSigninCheck } from 'reactfire';

const AuthBlock = () => {
  const { status, data: signInCheckResult } = useSigninCheck();
  const auth = useAuth()
  const location = useLocation()
  console.log(location)

  if (status == 'loading') {
    return <CircularProgress />
  }
  if (signInCheckResult.signedIn === true) {
    return <Button variant="outlined" onClick={() => auth.signOut()} sx={{color: 'white'}}>Sign Out</Button>
  }
  return <Link to={`/login?returnTo=${location.pathname}`}><Button sx={{color: 'white'}}>Sign In</Button></Link>
}

export const Layout = () => {
  return (
    <Container sx={{maxWidth: '1000px'}}>
      <Stack spacing={2}>
        <AppBar position="static">
          <Toolbar>
            <Typography sx={{flexGrow: 1 }}>
              MyConReg.com
            </Typography>
            <Link to={`/admin`}><Button sx={{color: 'white'}}>Admin</Button></Link>
            <AuthBlock />
          </Toolbar>
        </AppBar>
        <Box>
          <Outlet />
        </Box>
      </Stack>
    </Container>
  )
}