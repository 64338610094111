import { useFirestore, useFirestoreCollectionData, useFirestoreDocDataOnce, useFirestoreDocOnce, useSigninCheck } from "reactfire"
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore'
import { useForm } from "react-hook-form"
import { Link, useParams } from "react-router-dom"
import { Button, CircularProgress, TextField } from "@mui/material"
import { SQUARE_IS_SANDBOX, SQUARE_CLIENT_ID } from "../../config"
import CryptoJS from 'crypto-js'
import { useState } from 'react'
import { OrgAdminEventContainer } from "./EventScreens"

export const AdminOrgContainer = () => {
  const { orgId } = useParams();
  const { status: authStatus, data: signInCheckResult } = useSigninCheck();

  if (authStatus === 'loading') {
    return <CircularProgress />
  }
  return <AdminOrgDetailScreen orgId={orgId} uid={signInCheckResult.user.uid} />
}

const AdminOrgDetailScreen = ({ orgId, uid }) => {
  const adminRef = doc(useFirestore(), 'organizations', orgId, 'admins', uid)
  const { status, data } = useFirestoreDocDataOnce(adminRef)
  if (status == 'loading') {
    return <CircularProgress />
  }

  return (
    <>
      <Link to="/admin"><Button>Return to Organization List</Button></Link>
      <h2>
        Admin Page for {orgId}
      </h2>
      {data && data.permission_level >= 3 && (<CheckForSquareOrButton orgId={orgId} />)}
      <OrgAdminEventList orgId={orgId} uid={uid} />
    </>
  )
}

const CheckForSquareOrButton = ({ orgId }) => {
  const squareRef = doc(useFirestore(), 'organizations', orgId, 'payment_types', 'square')
  const { status, data } = useFirestoreDocDataOnce(squareRef)
  if (status == 'loading') {
    return <CircularProgress />
  }
  if (data) {
    return <p>Square is connected!</p>
  }
  const beginSquareProcess = () => {
    const validationToken = CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Base64)
    localStorage.setItem('__squareLink', JSON.stringify({validationToken, orgId}))
    const domain = SQUARE_IS_SANDBOX ? 'connect.squareupsandbox.com' : 'connect.squareup.com'
    const url = `https://${domain}/oauth2/authorize?client_id=${SQUARE_CLIENT_ID}&scope=PAYMENTS_WRITE+PAYMENTS_WRITE_ADDITIONAL_RECIPIENTS+PAYMENTS_READ+MERCHANT_PROFILE_READ+ORDERS_WRITE&state=${validationToken}${SQUARE_IS_SANDBOX ? '' : '&session=false'}`
    window.location.href = url
  }
  return (
    <Button onClick={beginSquareProcess}>Connect to Square</Button>
  )
}

const OrgAdminEventList = ({ orgId }) => {
  const eventsRef = collection(useFirestore(), 'organizations', orgId, 'events')
  const { status, data } = useFirestoreCollectionData(eventsRef)
  if (status === 'loading') {
    return <CircularProgress />
  }

  return (
    <>
      {data.map(entry => <Link to={`/admin/${orgId}/${entry.id}`}><Button>{entry.id} - {entry.details.name}</Button></Link>)}
    </>
  )
}

